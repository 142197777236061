import * as http from './http';
import ApiService from './service';

const serviceEndpoint = 'product';

export default class UserService extends ApiService {
  public async searchProduct(
    searchKey: string,
    page: number,
    filters?: any,
    sort?: string
  ) {
    const url = `${
      this.apiDomain
    }/${serviceEndpoint}/productsList/website_id/1/subsite_id/1/languageID/${
      this.languageId ? this.languageId.toString() : '1'
    }/product`;
    const data = new FormData();

    for (const key of Object.keys(filters)) {
      if (filters[key] && filters[key] !== '0') {
        data.append(key, filters[key]);
      }
    }
    data.append('page', page.toString());
    if (searchKey) {
      data.append('term', searchKey);
    }

    if (sort) {
      data.append('sortBy', sort);
    }
    const token = this.customerToken || this.token;
    data.append('userToken', token ? token : '');
    if (this.customerToken) {
      data.append('salespersonToken', this.token ? this.token : '');
    }
    const response = await http.postAsFormData(url, data);

    return { data: response.data };
  }

  public async productDetails(productID: number) {
    const url = `${
      this.apiDomain
    }/${serviceEndpoint}/details/website_id/1/subsite_id/1/languageID/${
      this.languageId ? this.languageId.toString() : '1'
    }/productID/${productID}/userToken/${this.token ? this.token : ''}`;
    const data = new FormData();
    const response = await http.get(url);
    return { data: response.data };
  }
  public async getFrequentOrders(page: number) {
    const url = `${
      this.apiDomain
    }/order/frequentorders/website_id/1/subsite_id/1/languageID/${
      this.languageId ? this.languageId.toString() : '1'
    }/userToken/${this.token ? this.token : ''}/page/${page}`;

    const response = await http.get(url);
    return { data: response.data };
  }

  public async getOfferProducts(
    searchKey: string,
    page: number,
    filters?: any,
    sort?: string,
    pageData?: number
  ) {
    const url = `${this.apiDomain}/${serviceEndpoint}/offerproducts/`;
    const data = new FormData();

    for (const key of Object.keys(filters)) {
      if (filters[key] && filters[key] !== '0') {
        data.append(key, filters[key]);
      }
    }
    data.append('page', page.toString());
    if (searchKey) {
      data.append('term', searchKey);
    }

    if (sort) {
      data.append('sortBy', sort);
    }
    if (page > 1) {
      data.append('pageData', pageData ? pageData.toString() : '0');
    }
    const token = this.customerToken || this.token;
    data.append('userToken', token ? token : '');
    if (this.customerToken) {
      data.append('salespersonToken', this.token ? this.token : '');
    }
    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append(
      'languageID',
      this.languageId ? this.languageId.toString() : '1'
    );
    const response = await http.postAsFormData(url, data);
    return { data: response.data };
  }

  public async getBannerOfferProducts(ruleID: number, page: number) {
    const url = `${this.apiDomain}/${serviceEndpoint}/bannerProducts/`;
    const data = new FormData();

    if (ruleID) {
      data.append('ruleID', ruleID.toString());
    }
    if (page) {
      data.append('page', page.toString());
    }
    const token = this.customerToken || this.token;
    data.append('userToken', token ? token : '');
    if (this.customerToken) {
      data.append('salespersonToken', this.token ? this.token : '');
    }
    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append(
      'languageID',
      this.languageId ? this.languageId.toString() : '1'
    );
    const response = await http.postAsFormData(url, data);
    return { data: response.data };
  }

  public async notifyProduct(productID: number, productOptionID?: number) {
    const url = `${this.apiDomain}/${serviceEndpoint}/notifyproduct`;
    const data = new FormData();
    data.append('productID', productID ? productID.toString() : '');
    data.append(
      'productOptionID',
      productOptionID ? productOptionID.toString() : ''
    );

    const token = this.customerToken || this.token;
    data.append('userToken', token ? token : '');
    if (this.customerToken) {
      data.append('salespersonToken', this.token ? this.token : '');
    }
    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append(
      'languageID',
      this.languageId ? this.languageId.toString() : '1'
    );

    return http.postAsFormData(url, data);
  }
}
