import * as http from './http';
import ApiService from './service';

export default class BundleService extends ApiService {
  public async getBundle() {
    const url = `${this.apiDomain}/bundle/index/`;
    const data = new FormData();
    const token = this.customerToken || this.token;
    data.append('userToken', token ? token : '');
    if (this.customerToken) {
      data.append('salespersonToken', this.token ? this.token : '');
    }
    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append(
      'languageID',
      this.languageId ? this.languageId.toString() : '1'
    );

    return http.postAsFormData(url, data);
  }
  public async getBundleProduct(ruleID: number) {
    const url = `${this.apiDomain}/bundle/details/`;
    const data = new FormData();
    data.append('ruleID', ruleID ? ruleID.toString() : '');
    if (this.customerToken) {
      data.append('salespersonToken', this.token ? this.token : '');
    }
    const token = this.customerToken || this.token;
    data.append('userToken', token ? token : '');
    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append(
      'languageID',
      this.languageId ? this.languageId.toString() : '1'
    );

    return http.postAsFormData(url, data);
  }
  public async addBundleProduct(ruleID: number, cartID: number) {
    const url = `${this.apiDomain}/cart/bundleAddItem`;
    const data = new FormData();

    data.append('ruleID', ruleID ? ruleID.toString() : '');
    if (cartID) {
      data.append('cartID', cartID ? cartID.toString() : '');
    }

    const token = this.customerToken || this.token;
    data.append('userToken', token ? token : '');
    if (this.customerToken) {
      data.append('salespersonToken', this.token ? this.token : '');
    }
    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append(
      'languageID',
      this.languageId ? this.languageId.toString() : '1'
    );

    return http.postAsFormData(url, data);
  }

  public async updateBundleProduct(
    bundleID: number,
    cartID: number,
    mode: string,
    quantity?: number
  ) {
    const url = `${this.apiDomain}/cart/updatecart`;
    const data = new FormData();

    data.append('bundleID', bundleID ? bundleID.toString() : '');
    data.append('cartID', cartID ? cartID.toString() : '');
    if (quantity) {
      data.append('quantity', quantity ? quantity.toString() : '');
    }
    const token = this.customerToken || this.token;
    data.append('userToken', token ? token : '');
    if (this.customerToken) {
      data.append('salespersonToken', this.token ? this.token : '');
    }
    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append(
      'languageID',
      this.languageId ? this.languageId.toString() : '1'
    );
    data.append('mode', mode);

    return http.postAsFormData(url, data);
  }
  // public async getFAQ() {
  //   const url = `${this.apiDomain}/faq/website_id/1/userToken/${
  //     this.token ? this.token : ''
  //   }/subsite_id/1/languageID/1/platform/Web/`;

  //   const response = await http.get(url);
  //   return { data: response.data };
  // }

  // public async getTerms() {
  //   const token = this.token ? this.token : '';
  //   const url = `${this.apiDomain}/user/gettermsofuse/website_id/1/subsite_id/1/languageID/1/userToken/${token}`;
  //   return http.get(url, token);
  // }

  // public async getCategory() {
  //   const url = `${this.apiDomain}/product/categories/website_id/1/userToken/${
  //     this.token ? this.token : ''
  //   }/subsite_id/1/languageID/1/platform/Web/`;

  //   const response = await http.get(url);
  //   return { data: response.data };
  // }
}
