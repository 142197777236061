import { Dispatch } from 'redux';

import api from '../../api';
import { types as commonTypes } from './common';
import { Product } from '../../models/product';
import { toastr } from 'react-redux-toastr';

// action types
export const types = {
  // Get product list
  SEARCH_PRODUCT_REQUEST: 'PRODUCT/SEARCH_PRODUCT_REQUEST',
  SEARCH_PRODUCT_SUCCESS: 'PRODUCT/SEARCH_PRODUCT_SUCCESS',
  SEARCH_PRODUCT_ERROR: 'PRODUCT/SEARCH_PRODUCT_ERROR',

  // Get product details
  PRODUCT_DETAIL_REQUEST: 'PRODUCT/PRODUCT_DETAIL_REQUEST',
  PRODUCT_DETAIL_SUCCESS: 'PRODUCT/PRODUCT_DETAIL_SUCCESS',
  PRODUCT_DETAIL_ERROR: 'PRODUCT/PRODUCT_DETAIL_ERROR',

  FREQUENT_ORDER_REQUEST: 'CART/FREQUENT_ORDER_REQUEST',
  FREQUENT_ORDER_SUCCESS: 'CART/FREQUENT_ORDER_SUCCESS',
  FREQUENT_ORDER_ERROR: 'CART/FREQUENT_ORDER_ERROR',

  // Get offer products list
  OFFER_PRODUCT_REQUEST: 'PRODUCT/OFFER_PRODUCT_REQUEST',
  OFFER_PRODUCT_SUCCESS: 'PRODUCT/OFFER_PRODUCT_SUCCESS',
  OFFER_PRODUCT_ERROR: 'PRODUCT/OFFER_PRODUCT_ERROR',

  // Get banner offer products list
  BANNER_PRODUCT_REQUEST: 'PRODUCT/BANNER_PRODUCT_REQUEST',
  BANNER_PRODUCT_SUCCESS: 'PRODUCT/BANNER_PRODUCT_SUCCESS',
  BANNER_PRODUCT_ERROR: 'PRODUCT/BANNER_PRODUCT_ERROR',

  // Notify products
  NOTIFY_PRODUCT_REQUEST: 'PRODUCT/NOTIFY_PRODUCT_REQUEST',
  NOTIFY_PRODUCT_SUCCESS: 'PRODUCT/NOTIFY_PRODUCT_SUCCESS',
  NOTIFY_PRODUCT_ERROR: 'PRODUCT/NOTIFY_PRODUCT_ERROR',

  RESET_DATA: 'CART/RESET_DATA',
};

export interface ProductState {
  products?: Product[];
  searchCompleted: boolean;
  isSearching: boolean;
  totalCount?: number;
  totalFrequentCount?: number;
  perPageCount?: number;
  newSearch?: boolean;
  isLoadingDetails: boolean;
  isDetailsLoaded: boolean;
  product?: Product;
  productID?: number;
  isProductLoading: boolean;
  isProductLoaded: boolean;
  frequentOrders?: Product[];
  productFilter?: any;
  offers?: Product[];
  isOfferLoading: boolean;
  isOfferLoaded: boolean;
  totalOfferCount?: number;
  newOfferSearch?: boolean;
  offerFilter?: any;
  bannerOffers?: Product[];
  isBannerOfferLoading: boolean;
  isBannerOfferLoaded: boolean;
  totalBannerOfferCount?: number;
  searchLogID?: number;
  pageData?: number;
}

const initialState: ProductState = {
  searchCompleted: false,
  isSearching: false,
  newSearch: false,
  isLoadingDetails: false,
  isDetailsLoaded: false,
  productID: 0,
  isProductLoading: false,
  isProductLoaded: false,
  isOfferLoading: false,
  isOfferLoaded: false,
  isBannerOfferLoading: false,
  isBannerOfferLoaded: false,
  searchLogID: 0,
};

export default (
  state: ProductState = initialState,
  action: any
): ProductState => {
  switch (action.type) {
    case types.RESET_DATA:
      return {
        ...initialState,
      };

    // product list
    case types.SEARCH_PRODUCT_REQUEST:
      return {
        ...state,
        isSearching: true,
        newSearch: action.newSearch,
      };
    case types.SEARCH_PRODUCT_SUCCESS:
      const products =
        Number(action.page) <= 1
          ? [...action.data.result]
          : [...(state.products ? state.products : []), ...action.data.result];

      return {
        ...state,
        searchCompleted: !!action.data,
        products,
        totalCount: action.data ? action.data.totalCount : 1,
        perPageCount: action.data ? Number(action.data.perPageCount) : 10,
        isSearching: false,
        newSearch: false,
        productFilter: action.data ? action.data.filter : null,
        searchLogID: action.data ? action.data.searchLogID : 0,
      };
    case types.SEARCH_PRODUCT_ERROR:
      return {
        ...state,
        isSearching: false,
        products: [],
        totalCount: 0,
        searchCompleted: true,
        newSearch: false,
      };

    case types.PRODUCT_DETAIL_REQUEST:
      return { ...state, isLoadingDetails: true, isDetailsLoaded: false };
    case types.PRODUCT_DETAIL_SUCCESS:
      return {
        ...state,
        isDetailsLoaded: !!action.data,
        product: action.data ? action.data.result[0] : null,
        isLoadingDetails: false,
      };
    case types.PRODUCT_DETAIL_ERROR:
      return {
        ...state,
        isLoadingDetails: false,
        isDetailsLoaded: true,
      };
    case types.FREQUENT_ORDER_REQUEST:
      return {
        ...state,
        isProductLoading: true,
        isProductLoaded: false,
      };
    case types.FREQUENT_ORDER_SUCCESS:
      const frequentOrders =
        Number(action.page) <= 1
          ? [...action.data.result]
          : [
              ...(state.frequentOrders ? state.frequentOrders : []),
              ...action.data.result,
            ];

      return {
        ...state,
        frequentOrders,
        isProductLoading: false,
        isProductLoaded: true,
        totalFrequentCount: action.data ? action.data.totalCount : 1,
        perPageCount: 10,
      };
    case types.FREQUENT_ORDER_ERROR:
      return {
        ...state,
        isProductLoading: false,
        isProductLoaded: true,
        totalFrequentCount: 0,
      };

    // offer product list
    case types.OFFER_PRODUCT_REQUEST:
      return {
        ...state,
        isOfferLoading: true,
        newOfferSearch: action.newSearch,
      };
    case types.OFFER_PRODUCT_SUCCESS:
      const offers =
        Number(action.page) <= 1
          ? [...action.data.result]
          : [...(state.offers ? state.offers : []), ...action.data.result];

      return {
        ...state,
        isOfferLoaded: !!action.data,
        offers,
        totalOfferCount: action.data ? action.data.totalCount : 1,
        perPageCount: action.data ? Number(action.data.perPageCount) : 10,
        isOfferLoading: false,
        newOfferSearch: false,
        offerFilter: action.data ? action.data.filter : null,
        pageData: action.data?.pageData,
      };

    case types.OFFER_PRODUCT_ERROR:
      return {
        ...state,
        isOfferLoading: false,
        offers: [],
        totalOfferCount: 0,
        isOfferLoaded: true,
        newOfferSearch: false,
      };

    // banner offer product list
    case types.BANNER_PRODUCT_REQUEST:
      return {
        ...state,
        isBannerOfferLoading: true,
      };
    case types.BANNER_PRODUCT_SUCCESS:
      const bannerOffers =
        Number(action.page) <= 1
          ? [...action.data.result]
          : [
              ...(state.bannerOffers ? state.bannerOffers : []),
              ...action.data.result,
            ];

      return {
        ...state,
        isBannerOfferLoaded: !!action.data,
        bannerOffers,
        totalBannerOfferCount: action.data ? action.data.totalCount : 1,
        perPageCount: action.data ? Number(action.data.perPageCount) : 10,
        isBannerOfferLoading: false,
      };

    case types.BANNER_PRODUCT_ERROR:
      return {
        ...state,
        isBannerOfferLoading: false,
        offers: [],
        totalBannerOfferCount: 0,
        isBannerOfferLoaded: true,
      };
    default:
      return state;
  }
};

// action creators & async actions
export const actions = {
  clearData: (onComplete?: () => void) => async (dispatch: Dispatch) => {
    await dispatch({ type: types.RESET_DATA });
    if (onComplete) {
      onComplete();
    }
  },
  searchProduct: (
    searchKey: string,
    page: number,
    filters?: any,
    sort?: string,
    newSearch?: boolean
  ) => async (dispatch: Dispatch, getState: () => any) => {
    const state = getState();

    if (!state.authUser.authToken) {
      return;
    }
    api.setToken(state.authUser.authToken);
    api.setCustomerLanguage(state.authUser.languageID);
    api.setCustomerLanguageKey(state.authUser.languageKey);
    dispatch({ type: types.SEARCH_PRODUCT_REQUEST, newSearch });
    try {
      const response = await api.product.searchProduct(
        searchKey,
        page,
        filters,
        sort
      );
      const { data } = response;

      dispatch({ type: types.SEARCH_PRODUCT_SUCCESS, data, page });
    } catch (error) {
      dispatch({ type: types.SEARCH_PRODUCT_ERROR });
      // toastr.error('Error', 'Error Fetching profile');
      throw error;
    }
  },

  productDetails: (
    productID: number,
    onSuccess?: (product: Product) => void
  ) => async (dispatch: Dispatch, getState: () => any) => {
    const state = getState();

    if (!state.authUser.authToken) {
      return;
    }
    api.setToken(state.authUser.authToken);
    api.setCustomerLanguage(state.authUser.languageID);
    api.setCustomerLanguageKey(state.authUser.languageKey);
    dispatch({ type: types.PRODUCT_DETAIL_REQUEST });
    try {
      const response = await api.product.productDetails(productID);
      const { data } = response;
      dispatch({ type: types.PRODUCT_DETAIL_SUCCESS, data });
      if (onSuccess) {
        onSuccess(data && data.result ? data.result[0] : null);
      }
    } catch (error) {
      dispatch({ type: types.PRODUCT_DETAIL_ERROR });
      throw error;
    }
  },
  getFrequentOrders: (page: number) => async (
    dispatch: Dispatch,
    getState: () => any
  ) => {
    const state = getState();

    if (!state.authUser.authToken) {
      return;
    }
    api.setToken(state.authUser.authToken);
    api.setCustomerLanguage(state.authUser.languageID);
    api.setCustomerLanguageKey(state.authUser.languageKey);
    dispatch({ type: types.FREQUENT_ORDER_REQUEST });

    try {
      const response = await api.product.getFrequentOrders(page);
      const { data } = response;
      dispatch({ type: types.FREQUENT_ORDER_SUCCESS, data, page });
    } catch (error) {
      dispatch({ type: types.FREQUENT_ORDER_ERROR });
      throw error;
    }
  },
  getOfferProducts: (
    searchKey: string,
    page: number,
    filters?: any,
    sort?: string,
    newSearch?: boolean,
    pageData?: number
  ) => async (dispatch: Dispatch, getState: () => any) => {
    const state = getState();

    if (!state.authUser.authToken) {
      return;
    }
    api.setToken(state.authUser.authToken);
    api.setCustomerLanguage(state.authUser.languageID);
    api.setCustomerLanguageKey(state.authUser.languageKey);
    dispatch({ type: types.OFFER_PRODUCT_REQUEST, newSearch });
    try {
      const response = await api.product.getOfferProducts(
        searchKey,
        page,
        filters,
        sort,
        pageData
      );
      const { data } = response;
      dispatch({ type: types.OFFER_PRODUCT_SUCCESS, data, page });
    } catch (error) {
      dispatch({ type: types.OFFER_PRODUCT_ERROR });
      // toastr.error('Error', 'Error Fetching profile');
      throw error;
    }
  },

  getBannerOfferProducts: (ruleID: number, page: number) => async (
    dispatch: Dispatch,
    getState: () => any
  ) => {
    const state = getState();

    if (!state.authUser.authToken) {
      return;
    }
    api.setToken(state.authUser.authToken);
    api.setCustomerLanguage(state.authUser.languageID);
    api.setCustomerLanguageKey(state.authUser.languageKey);
    dispatch({ type: types.BANNER_PRODUCT_REQUEST });
    try {
      const response = await api.product.getBannerOfferProducts(ruleID, page);
      const { data } = response;
      dispatch({ type: types.BANNER_PRODUCT_SUCCESS, data, page });
    } catch (error) {
      dispatch({ type: types.BANNER_PRODUCT_ERROR });
      // toastr.error('Error', 'Error Fetching profile');
      throw error;
    }
  },

  notifyProduct: (
    productID: number,
    productOptionID?: number,
    onSuccess?: () => void
  ) => async (dispatch: Dispatch) => {
    dispatch({ type: types.NOTIFY_PRODUCT_REQUEST });
    try {
      const response = await api.product.notifyProduct(
        productID,
        productOptionID
      );
      const { data } = response;
      if (data.response === 'Failure') {
        toastr.error('Error', data.errorMsg);
        dispatch({
          type: types.NOTIFY_PRODUCT_ERROR,
          data: { errorMessage: 'Unable to process your request' },
        });
      } else {
        dispatch({
          type: types.NOTIFY_PRODUCT_SUCCESS,
          data,
        });
        if (onSuccess) {
          onSuccess();
        }
        toastr.success(
          'Success',
          'We will notify you through email, when this item available'
        );
      }
    } catch (error) {
      dispatch({
        type: types.NOTIFY_PRODUCT_ERROR,
        data: { errorMessage: 'Unable to process your request' },
      });
    }
  },
};
