import { Dispatch } from 'redux';
import { CartResponse, CheckoutSubmit, Cart } from '../../models/cart';
import { toastr } from 'react-redux-toastr';
import api from '../../api';
import i18n from '../../i18n';

export const types = {
  RESET_DATA: 'CART/RESET_DATA',

  CHECKOUT_REQUEST: 'CART/CHECKOUT_REQUEST',
  CHECKOUT_SUCCESS: 'CART/CHECKOUT_SUCCESS',
  CHECKOUT_ERROR: 'CART/CHECKOUT_ERROR',

  ADDRESS_UPDATE_REQUEST: 'CART/ADDRESS_UPDATE_REQUEST',
  ADDRESS_UPDATE_SUCCESS: 'CART/ADDRESS_UPDATE_SUCCESS',
  ADDRESS_UPDATE_ERROR: 'CART/ADDRESS_UPDATE_ERROR',

  CHECKOUT_SUBMIT_REQUEST: 'CART/CHECKOUT_SUBMIT_REQUEST',
  CHECKOUT_SUBMIT_SUCCESS: 'CART/CHECKOUT_SUBMIT_SUCCESS',
  CHECKOUT_SUBMIT_ERROR: 'CART/CHECKOUT_SUBMIT_ERROR',

  SET_FEE_REQUEST: 'CART/SET_FEE_REQUEST',
  SET_FEE_SUCCESS: 'CART/SET_FEE_SUCCESS',
  SET_FEE_ERROR: 'CART/SET_FEE_ERROR',
};

export interface CheckoutState {
  isLoading: boolean;
  isLoaded: boolean;
  items?: CartResponse[];
  error?: string;
  checkoutDetails?: any;
  checkoutDetailsLoading: boolean;
  checkoutDetailsLoaded: boolean;
  isAdding: boolean;
  addingProductID?: number;
  checkoutError?: string;
  feeSetUp?: Cart;
}

const initialState: CheckoutState = {
  isLoading: false,
  isLoaded: false,
  checkoutDetailsLoading: false,
  checkoutDetailsLoaded: false,
  isAdding: false,
  checkoutDetails: undefined,
  addingProductID: 0,
  items: undefined,
  checkoutError: '',
};

export default (
  state: CheckoutState = initialState,
  action: any
): CheckoutState => {
  switch (action.type) {
    case types.RESET_DATA:
      return {
        ...initialState,
      };

    case types.CHECKOUT_REQUEST:
      return {
        ...state,
        checkoutDetailsLoading: false,
        checkoutDetailsLoaded: true,
        checkoutError: undefined,
      };
    case types.CHECKOUT_SUCCESS:
      return {
        ...state,
        checkoutDetails: action.data ? action.data.result : null,
        checkoutError: undefined,
      };
    case types.CHECKOUT_ERROR:
      return {
        ...state,
        checkoutDetailsLoading: false,
        checkoutDetailsLoaded: true,
        checkoutError: action.data ? action.data.errorMsg : '',
      };
    case types.CHECKOUT_SUBMIT_REQUEST:
      return {
        ...state,
        error: undefined,
      };
    case types.CHECKOUT_SUBMIT_SUCCESS:
      return {
        ...state,
        error: undefined,
      };
    case types.CHECKOUT_SUBMIT_ERROR:
      return {
        ...state,
      };
    case types.SET_FEE_REQUEST:
      return {
        ...state,
        feeSetUp: undefined,
        error: undefined,
      };
    case types.SET_FEE_SUCCESS:
      return {
        ...state,
        feeSetUp: action.data ? action.data.result : null,
        error: undefined,
      };
    case types.SET_FEE_ERROR:
      return {
        ...state,
        // error: action.data.errorMessage,
      };
    default:
      return state;
  }
};

export const actions = {
  clearData: (onComplete?: () => void) => async (dispatch: Dispatch) => {
    await dispatch({ type: types.RESET_DATA });
    if (onComplete) {
      onComplete();
    }
  },

  getCheckoutDetails: (cartID: number) => async (
    dispatch: Dispatch,
    getState: () => any
  ) => {
    const state = getState();

    if (!state.authUser.authToken) {
      return;
    }
    api.setToken(state.authUser.authToken);
    api.setCustomerLanguage(state.authUser.languageID);
    api.setCustomerLanguageKey(state.authUser.languageKey);
    api.setCustomerToken(state.authUser.customerToken);
    dispatch({ type: types.CHECKOUT_REQUEST });
    try {
      const response = await api.cart.getCheckoutDetails(cartID);
      const { data } = response;
      if (data.response === 'Failure') {
        dispatch({ type: types.CHECKOUT_ERROR, data });
      } else {
        dispatch({ type: types.CHECKOUT_SUCCESS, data });
      }
    } catch (error) {
      dispatch({ type: types.CHECKOUT_ERROR });
      // toastr.error('Error', 'Error Fetching profile');
      throw error;
    }
  },
  updateAddress: (
    userShippingID: number,
    cartID: number,
    onSuccess?: () => void
  ) => async (dispatch: Dispatch, getState: () => any) => {
    dispatch({ type: types.ADDRESS_UPDATE_REQUEST });
    const state = getState();
    const lng = `${state.authUser.languageKey}`;
    try {
      const response = await api.cart.updateAddress(userShippingID, cartID);
      const { data } = response;
      if (data.response === 'Failure') {
        toastr.error('Error', data.errorMsg);
      } else {
        dispatch({
          type: types.ADDRESS_UPDATE_SUCCESS,
          data,
        });
        if (onSuccess) {
          onSuccess();
        }
      }
    } catch (error) {
      dispatch({
        type: types.ADDRESS_UPDATE_ERROR,
        data: { errorMessage: i18n.t('Unable to update address', { lng }) },
      });
    }
  },

  setPaymentFee: (
    paymentSelected: string,
    cartID: number,
    onSuccess?: () => void
  ) => async (dispatch: Dispatch, getState: () => any) => {
    dispatch({ type: types.SET_FEE_REQUEST });
    const state = getState();
    const lng = `${state.authUser.languageKey}`;
    try {
      const response = await api.cart.setPaymentFee(paymentSelected, cartID);
      const { data } = response;
      if (data.response === 'Failure') {
        toastr.error('Error', data.errorMsg);
      } else {
        dispatch({
          type: types.SET_FEE_SUCCESS,
          data,
        });
        if (onSuccess) {
          onSuccess();
        }
      }
    } catch (error) {
      dispatch({
        type: types.SET_FEE_ERROR,
        data: { errorMessage: i18n.t('Unable to set fee', { lng }) },
      });
    }
  },

  checkoutSubmit: (
    item: CheckoutSubmit,
    onSuccess?: (paymentMode?: string) => void,
    onError?: () => void
  ) => async (dispatch: Dispatch, getState: () => any) => {
    dispatch({ type: types.CHECKOUT_SUBMIT_REQUEST });
    const state = getState();
    const lng = `${state.authUser.languageKey}`;
    try {
      const response = await api.cart.checkoutSubmit(item);
      const { data } = response;
      if (data.response === 'Failure') {
        toastr.error('Error', data.errorMsg);
        dispatch({
          type: types.CHECKOUT_SUBMIT_ERROR,
          data: { errorMessage: i18n.t('Unable to checkout order', { lng }) },
        });
        if (onError) {
          onError();
        }
      } else {
        dispatch({
          type: types.CHECKOUT_SUBMIT_SUCCESS,
          data,
        });
        if (onSuccess) {
          onSuccess(item.paymentSettingsGroupKeySelected);
        }
        // toastr.success('Success', i18n.t('Order successful', { lng }));
      }
    } catch (error) {
      dispatch({
        type: types.CHECKOUT_SUBMIT_ERROR,
        data: { errorMessage: i18n.t('Unable to checkout order', { lng }) },
      });
      toastr.error('Error', i18n.t('Unable to checkout order', { lng }));
      if (onError) {
        onError();
      }
    }
  },
};
