import { Dispatch } from 'redux';
import { CartResponse, Cart, CartItem } from '../../models/cart';
import { toastr } from 'react-redux-toastr';
import { Product } from '../../models/product';
import api from '../../api';
import i18n from '../../i18n';

export const types = {
  RESET_DATA: 'CART/RESET_DATA',

  CART_ADD_REQUEST: 'CART/CART_ADD_REQUEST',
  CART_ADD_SUCCESS: 'CART/CART_ADD_SUCCESS',
  CART_ADD_ERROR: 'CART/CART_ADD_ERROR',

  CART_UPDATE_REQUEST: 'CART/CART_UPDATE_REQUEST',
  CART_UPDATE_SUCCESS: 'CART/CART_UPDATE_SUCCESS',
  CART_UPDATE_ERROR: 'CART/CART_UPDATE_ERROR',

  PROMOCODE_ADD_REQUEST: 'CART/PROMOCODE_ADD_REQUEST',
  PROMOCODE_ADD_SUCCESS: 'CART/PROMOCODE_ADD_SUCCESS',
  PROMOCODE_ADD_ERROR: 'CART/PROMOCODE_ADD_ERROR',

  PROMOCODE_REMOVE_REQUEST: 'CART/PROMOCODE_REMOVE_REQUEST',
  PROMOCODE_REMOVE_SUCCESS: 'CART/PROMOCODE_REMOVE_SUCCESS',
  PROMOCODE_REMOVE_ERROR: 'CART/PROMOCODE_REMOVE_ERROR',

  CART_REQUEST: 'CART/CART_REQUEST',
  CART_SUCCESS: 'CART/CART_SUCCESS',
  CART_ERROR: 'CART/CART_ERROR',
};

export interface CartState {
  isLoading: boolean;
  isLoaded: boolean;
  items?: CartResponse[];
  error?: string;
  cart?: Cart;
  isPromotionApplied?: boolean;
  isCartLoading: boolean;
  isCartLoaded: boolean;
  appliedPromoCode?: string;
  isAdding: boolean;
  addingProductID?: number;
}

const initialState: CartState = {
  isLoading: false,
  isLoaded: false,
  isPromotionApplied: false,
  isCartLoading: false,
  isCartLoaded: false,
  isAdding: false,
  cart: undefined,
  addingProductID: 0,
  appliedPromoCode: undefined,
  items: undefined,
};

export default (state: CartState = initialState, action: any): CartState => {
  switch (action.type) {
    case types.RESET_DATA:
      return {
        ...initialState,
      };
    case types.CART_REQUEST:
      return {
        ...state,
        error: undefined,
        isCartLoading: true,
        isCartLoaded: state.cart ? true : false,
      };
    case types.CART_SUCCESS:
      return {
        ...state,
        cart:
          action.data && action.data.result
            ? action.data.result.cartData
            : null,
        error: undefined,
        isCartLoading: false,
        isCartLoaded: true,
      };
    case types.CART_ERROR:
      return {
        ...state,
        // error: action.data.errorMessage,
        isCartLoading: false,
        isCartLoaded: true,
      };
    case types.CART_ADD_REQUEST:
      return {
        ...state,
        error: undefined,
        isLoading: true,
        isLoaded: false,
        isAdding: true,
        addingProductID: action.productId,
      };
    case types.CART_ADD_SUCCESS:
      return {
        ...state,
        // cart: action.data ? action.data.result : null,
        error: undefined,
        isLoading: false,

        isLoaded: true,
        isAdding: false,
        addingProductID: 0,
      };
    case types.CART_ADD_ERROR:
      return {
        ...state,
        // error: action.data.errorMessage,
        isLoading: false,
        isLoaded: true,
        isAdding: false,
        addingProductID: 0,
      };
    case types.CART_UPDATE_REQUEST:
      return {
        ...state,
        error: undefined,
        isLoading: true,
        isLoaded: false,
      };
    case types.CART_UPDATE_SUCCESS:
      return {
        ...state,
        // cart: action.data ? action.data.result : null,
        error: undefined,
        isLoading: false,
        isLoaded: true,
        // isPromotionApplied:
        //   action.data &&
        //   action.data.result &&
        //   action.data.result.items &&
        //   action.data.result.items.length > 0
        //     ? state.isPromotionApplied
        //     : false,
        // appliedPromoCode:
        //   action.data &&
        //   action.data.result &&
        //   action.data.result.items &&
        //   action.data.result.items.length > 0
        //     ? state.appliedPromoCode
        //     : '',
      };
    case types.CART_UPDATE_ERROR:
      return {
        ...state,
        // error: action.data.errorMessage,
        isLoading: false,
        isLoaded: true,
      };
    case types.PROMOCODE_ADD_REQUEST:
      return {
        ...state,
        error: undefined,
        isLoading: true,
        isLoaded: false,
      };
    case types.PROMOCODE_ADD_SUCCESS:
      return {
        ...state,
        cart: action.data
          ? {
              ...state.cart,
              ...action.data.result,
            }
          : { ...state.cart },
        error: undefined,
        isPromotionApplied: true,
        isLoading: false,
        isLoaded: true,
        appliedPromoCode: action.promoCode,
      };
    case types.PROMOCODE_ADD_ERROR:
      return {
        ...state,
        // error: action.data.errorMessage,
        isLoading: false,
        isLoaded: true,
      };
    case types.PROMOCODE_REMOVE_REQUEST:
      return {
        ...state,
        error: undefined,
        isLoading: true,
        isLoaded: false,
      };
    case types.PROMOCODE_REMOVE_SUCCESS:
      return {
        ...state,
        cart: action.data
          ? {
              ...state.cart,
              ...action.data.result,
            }
          : { ...state.cart },
        error: undefined,
        isPromotionApplied: false,
        isLoading: false,
        isLoaded: true,
        appliedPromoCode: '',
      };
    case types.PROMOCODE_REMOVE_ERROR:
      return {
        ...state,
        // error: action.data.errorMessage,
        isLoading: false,
        isLoaded: true,
      };

    default:
      return state;
  }
};

export const actions = {
  clearData: (onComplete?: () => void) => async (dispatch: Dispatch) => {
    await dispatch({ type: types.RESET_DATA });
    if (onComplete) {
      onComplete();
    }
  },
  addToCart: (
    product: Product,
    cartID?: number,
    onSuccess?: () => void
  ) => async (dispatch: Dispatch, getState: () => any) => {
    dispatch({ type: types.CART_ADD_REQUEST, productId: product.productID });
    const state = getState();
    const lng = `${state.authUser.languageKey}`;
    try {
      const response = await api.cart.AddItemtoCart(product, cartID);
      const { data } = response;
      if (data.response === 'Failure') {
        toastr.error('Error', data.errorMsg);
        dispatch({
          type: types.CART_ADD_ERROR,
          data: { errorMessage: i18n.t('Unable to add cart', { lng }) },
        });
      } else {
        dispatch({
          type: types.CART_ADD_SUCCESS,
          data,
        });
        if (onSuccess) {
          onSuccess();
        }
        toastr.success('Success', i18n.t('Product added to cart', { lng }));
      }
    } catch (error) {
      dispatch({
        type: types.CART_ADD_ERROR,
        data: { errorMessage: i18n.t('Unable to add cart', { lng }) },
      });
    }
  },
  updateCart: (
    item: CartItem,
    quantity: number,
    index?: number,
    onSuccess?: (quantity: number, index?: number) => void,
    onError?: (index: number) => void
  ) => async (dispatch: Dispatch, getState: () => any) => {
    dispatch({ type: types.CART_UPDATE_REQUEST });
    const state = getState();
    const lng = `${state.authUser.languageKey}`;
    try {
      if (item.orderItemQuantity === quantity) {
        if (onSuccess) {
          onSuccess(quantity, index);
        }
        return;
      }

      const response = await api.cart.updateQuantity(item, quantity);
      const { data } = response;
      if (data.response === 'Failure') {
        toastr.error('Error', data.errorMsg);
        dispatch({
          type: types.CART_UPDATE_ERROR,
          data: { errorMessage: i18n.t('Unable to add cart', { lng }) },
        });
        if (onError) {
          onError(index ? index : 0);
        }
      } else {
        dispatch({
          type: types.CART_UPDATE_SUCCESS,
          data,
        });
        if (onSuccess) {
          onSuccess(quantity, index);
        }
      }
    } catch (error) {
      dispatch({
        type: types.CART_UPDATE_ERROR,
        data: { errorMessage: i18n.t('Unable to add cart', { lng }) },
      });
    }
  },

  bulkOrder: (
    product: Product,
    quantity: number,
    cartID?: number,
    cartItemID?: number,
    onSuccess?: () => void
  ) => async (dispatch: Dispatch, getState: () => any) => {
    dispatch({ type: types.CART_ADD_REQUEST, productId: product.productID });
    const state = getState();
    const lng = `${state.authUser.languageKey}`;
    try {
      const response = await api.cart.bulkOrder(
        product,
        quantity,
        cartID,
        cartItemID
      );
      const { data } = response;
      if (data.response === 'Failure') {
        toastr.error('Error', data.errorMsg);
        dispatch({
          type: types.CART_ADD_ERROR,
          data: { errorMessage: i18n.t('Unable to add cart', { lng }) },
        });
        if (onSuccess) {
          onSuccess();
        }
      } else {
        dispatch({
          type: types.CART_ADD_SUCCESS,
          data,
        });
        if (onSuccess) {
          onSuccess();
        }
        toastr.success('Success', i18n.t('Product added to cart', { lng }));
      }
    } catch (error) {
      dispatch({
        type: types.CART_ADD_ERROR,
        data: { errorMessage: i18n.t('Unable to add cart', { lng }) },
      });
      if (onSuccess) {
        onSuccess();
      }
    }
  },
  removeCart: (
    item: CartItem,
    hideMessage?: boolean,
    onSuccess?: () => void
  ) => async (dispatch: Dispatch, getState: () => any) => {
    dispatch({ type: types.CART_UPDATE_REQUEST });
    const state = getState();
    const lng = `${state.authUser.languageKey}`;
    try {
      const response = await api.cart.removeCart(item);
      const { data } = response;
      if (data.response === 'Failure') {
        if (!hideMessage) {
          toastr.error('Error', data.errorMsg);
        }
      } else {
        dispatch({
          type: types.CART_UPDATE_SUCCESS,
          data,
        });
        if (onSuccess) {
          onSuccess();
        }
        if (!hideMessage) {
          toastr.success(
            'Success',
            i18n.t('Product removed from cart', { lng })
          );
        }
      }
    } catch (error) {
      dispatch({
        type: types.CART_UPDATE_ERROR,
        data: {
          errorMessage: i18n.t('Unable to remove item from cart', { lng }),
        },
      });
    }
  },
  applyPromoCode: (
    promoCode: string,
    cartID: number,
    onSuccess?: () => void
  ) => async (dispatch: Dispatch, getState: () => any) => {
    dispatch({ type: types.PROMOCODE_ADD_REQUEST });
    const state = getState();
    const lng = `${state.authUser.languageKey}`;
    try {
      const response = await api.cart.applyPromoCode(promoCode, cartID);
      const { data } = response;
      if (data.response === 'Failure') {
        toastr.error('Error', data.errorMsg);
        if (onSuccess) {
          onSuccess();
        }
      } else {
        dispatch({
          type: types.PROMOCODE_ADD_SUCCESS,
          data,
          promoCode,
        });
        if (onSuccess) {
          onSuccess();
        }
      }
    } catch (error) {
      dispatch({
        type: types.PROMOCODE_ADD_ERROR,
        data: { errorMessage: i18n.t('Unable to apply promoCode', { lng }) },
      });
      if (onSuccess) {
        onSuccess();
      }
    }
  },
  removePromoCode: (cartID: number) => async (
    dispatch: Dispatch,
    getState: () => any
  ) => {
    dispatch({ type: types.PROMOCODE_REMOVE_REQUEST });
    const state = getState();
    const lng = `${state.authUser.languageKey}`;
    try {
      const response = await api.cart.removePromoCode(cartID);
      const { data } = response;
      if (data.response === 'Failure') {
        toastr.error('Error', data.errorMsg);
      } else {
        dispatch({
          type: types.PROMOCODE_REMOVE_SUCCESS,
          data,
        });
      }
    } catch (error) {
      dispatch({
        type: types.PROMOCODE_REMOVE_ERROR,
        data: { errorMessage: i18n.t('Unable to remove promoCode', { lng }) },
      });
    }
  },

  getCart: (onSuccess?: (cartID: number) => void) => async (
    dispatch: Dispatch,
    getState: () => any
  ) => {
    const state = getState();

    if (!state.authUser.authToken) {
      return;
    }
    api.setToken(state.authUser.authToken);
    api.setCustomerLanguage(state.authUser.languageID);
    api.setCustomerLanguageKey(state.authUser.languageKey);
    api.setCustomerToken(state.authUser.customerToken);
    dispatch({ type: types.CART_REQUEST });
    try {
      const response = await api.cart.getCart();
      const { data } = response;
      dispatch({ type: types.CART_SUCCESS, data });
      const cartData = data && data.result ? data.result.cartData : null;
      if (onSuccess) {
        onSuccess(cartData ? cartData.cartID : 0);
      }
    } catch (error) {
      dispatch({ type: types.CART_ERROR });
      // toastr.error('Error', 'Error Fetching profile');
      throw error;
    }
  },
};
