import { stringify } from 'query-string';
import * as http from './http';
import ApiService from './service';
import { Customer } from '../../models/customer';
import { CrudListRequestModel } from '../models';

const serviceEndpoint = 'salesperson';

export default class SalesPersonService extends ApiService {
  public async getCustomerList(
    page?: number,
    keyWord?: string,
    status?: string
  ) {
    let url = `${
      this.apiDomain
    }/${serviceEndpoint}/customers/website_id/1/subsite_id/1/languageID/${
      this.languageId ? this.languageId.toString() : '1'
    }/salespersonToken/${this.token}/page/${page || 1}`;
    if (keyWord) {
      url = url + `/customer/${keyWord}`;
    }
    if (status) {
      url = url + `/status/${status}`;
    }
    // for (const key of Object.keys(filters)) {
    //   if (filters[key] && filters[key] !== '0') {
    //     data.append(key, filters[key]);
    //   }
    // }

    const response = await http.get(url, this.token);
    return { data: response.data };
  }

  public async customerCreate(userData: Customer) {
    const url = `${this.apiDomain}/${serviceEndpoint}/createcustomer`;
    const data = new FormData();
    data.append(
      'userFirstName',
      userData.userFirstName ? userData.userFirstName : ''
    );
    data.append(
      'userLastName',
      userData.userLastName ? userData.userLastName : ''
    );
    data.append('userPhone', userData.userPhone ? userData.userPhone : '');
    data.append(
      'userPhoneCountryCode',
      userData.userPhoneCountryCode ? userData.userPhoneCountryCode : ''
    );
    data.append(
      'userPhoneDialCode',
      userData.userPhoneDialCode ? userData.userPhoneDialCode : ''
    );

    data.append(
      'userDesignation',
      userData.userDesignation ? userData.userDesignation : ''
    );
    if (userData.userEmail) {
      data.append('userEmail', userData.userEmail ? userData.userEmail : '');
    }
    data.append(
      'accountCode',
      userData.accountCode ? userData.accountCode : ''
    );
    data.append(
      'billingName',
      userData.billingName ? userData.billingName : ''
    );
    data.append('tradeno', userData.tradeno ? userData.tradeno : '');
    data.append('trn', userData.trn ? userData.trn : '');
    data.append('salespersonToken', this.token ? this.token : '');
    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append(
      'languageID',
      this.languageId ? this.languageId.toString() : '1'
    );
    data.append('action', 'update');
    return http.postAsFormData(url, data);
  }

  public async customerCartList(page?: number, keyWord?: string) {
    let url = `${
      this.apiDomain
    }/${serviceEndpoint}/customercart/website_id/1/subsite_id/1/languageID/${
      this.languageId ? this.languageId.toString() : '1'
    }/salespersonToken/${this.token}/page/${page || 1}`;
    if (keyWord) {
      url = url + `/customer/${keyWord}`;
    }
    const response = await http.get(url, this.token);
    return { data: response.data };
  }

  public async CustomerOrderList(page?: number, filters?: any) {
    let url = `${
      this.apiDomain
    }/${serviceEndpoint}/ordercustomers/website_id/1/subsite_id/1/languageID/${
      this.languageId ? this.languageId.toString() : '1'
    }/salespersonToken/${this.token}/page/${page || 1}`;
    if (filters) {
      for (const key of Object.keys(filters)) {
        if (filters[key]) {
          url = url + `/${key}/${filters[key]}`;
        }
      }
    }
    const response = await http.get(url, this.token);
    return { data: response.data };
  }

  public async OrderList(page?: number, filters?: any) {
    let url = `${
      this.apiDomain
    }/${serviceEndpoint}/orderlist/website_id/1/subsite_id/1/languageID/${
      this.languageId ? this.languageId.toString() : '1'
    }/salespersonToken/${this.token}/page/${page || 1}`;
    if (filters) {
      for (const key of Object.keys(filters)) {
        if (filters[key]) {
          url = url + `/${key}/${filters[key]}`;
        }
      }
    }
    const response = await http.get(url, this.token);
    return { data: response.data };
  }

  public async CustomerHighOrderList(page?: number, keyWord?: string) {
    let url = `${
      this.apiDomain
    }/${serviceEndpoint}/highorders/website_id/1/subsite_id/1/languageID/${
      this.languageId ? this.languageId.toString() : '1'
    }/salespersonToken/${this.token}/page/${page || 1}`;
    if (keyWord) {
      url = url + `/customer/${keyWord}`;
    }
    const response = await http.get(url, this.token);
    return { data: response.data };
  }

  public async approveOrder(orderID: number, status: string) {
    const url = `${this.apiDomain}/${serviceEndpoint}/highorderstatus`;
    const data = new FormData();

    data.append('orderID', orderID ? orderID.toString() : '');
    data.append('status', status);
    data.append('salespersonToken', this.token ? this.token : '');

    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append(
      'languageID',
      this.languageId ? this.languageId.toString() : '1'
    );

    return http.postAsFormData(url, data);
  }

  public async getProfile() {
    const url = `${
      this.apiDomain
    }/${serviceEndpoint}/profile/website_id/1/subsite_id/1/languageID/${
      this.languageId ? this.languageId.toString() : '1'
    }/salespersonToken/${this.token}`;

    return http.get(url, this.token);
  }

  public async getPerformanceChart(
    type?: string,
    fromDate?: string,
    toDate?: string
  ) {
    let url = `${
      this.apiDomain
    }/${serviceEndpoint}/performance/website_id/1/subsite_id/1/languageID/${
      this.languageId ? this.languageId.toString() : '1'
    }/salespersonToken/${this.token}`;
    if (type) {
      url = url + `/type/${type}`;
    }
    if (fromDate) {
      url = url + `/fromMonth/${fromDate}`;
    }
    if (toDate) {
      url = url + `/toMonth/${toDate}`;
    }
    return http.get(url, this.token);
  }

  public async changeOrderStatus(
    orderID: number,
    status: string,
    reason?: string
  ) {
    const url = `${this.apiDomain}/${serviceEndpoint}/orderstatus`;
    const data = new FormData();

    data.append('orderID', orderID ? orderID.toString() : '');
    data.append('status', status);
    data.append('reason', reason ? reason : '');
    data.append('salespersonToken', this.token ? this.token : '');

    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append(
      'languageID',
      this.languageId ? this.languageId.toString() : '1'
    );

    return http.postAsFormData(url, data);
  }
}
