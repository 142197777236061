import ApiServiceDataStore from './data';

export default class ApiService {
  public store: ApiServiceDataStore;

  constructor(store: ApiServiceDataStore) {
    this.store = store;
  }

  get token() {
    return this.store.authToken;
  }

  get customerToken() {
    return this.store.customerToken;
  }

  get apiDomain() {
    return this.store.apiDomain;
  }

  get languageId() {
    return this.store.languageId;
  }

  get languageKey() {
    return this.store.languageKey;
  }
}
