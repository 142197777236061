import { Dispatch } from 'redux';
import { toastr } from 'react-redux-toastr';
import api from '../../api';
import { BundleOffer, BundleProduct } from '../../models/bundleOffer';

export const types = {
  RESET_DATA: 'COMMON/RESET_DATA',

  BUNDLE_REQUEST: 'BUNDLE/BUNDLE_REQUEST',
  BUNDLE_SUCCESS: 'BUNDLE/BUNDLE_SUCCESS',
  BUNDLE_ERROR: 'BUNDLE/BUNDLE_ERROR',

  BUNDLE_DETAILS_REQUEST: 'BUNDLE/BUNDLE_DETAILS_REQUEST',
  BUNDLE_DETAILS_SUCCESS: 'BUNDLE/BUNDLE_DETAILS_SUCCESS',
  BUNDLE_DETAILS_ERROR: 'BUNDLE/BUNDLE_DETAILS_ERROR',

  BUNDLE_PRODUCT_REQUEST: 'BUNDLE/BUNDLE_PRODUCT_REQUEST',
  BUNDLE_PRODUCT_SUCCESS: 'BUNDLE/BUNDLE_PRODUCT_SUCCESS',
  BUNDLE_PRODUCT_ERROR: 'BUNDLE/BUNDLE_PRODUCT_ERROR',

  BUNDLE_ADD_REQUEST: 'BUNDLE/BUNDLE_ADD_REQUEST',
  BUNDLE_ADD_SUCCESS: 'BUNDLE/BUNDLE_ADD_SUCCESS',
  BUNDLE_ADD_ERROR: 'BUNDLE/BUNDLE_ADD_ERROR',

  BUNDLE_DELETE_REQUEST: 'BUNDLE/BUNDLE_DELETE_REQUEST',
  BUNDLE_DELETE_SUCCESS: 'BUNDLE/BUNDLE_DELETE_SUCCESS',
  BUNDLE_DELETE_ERROR: 'BUNDLE/BUNDLE_DELETE_ERROR',

  BUNDLE_UPDATE_REQUEST: 'BUNDLE/BUNDLE_UPDATE_REQUEST',
  BUNDLE_UPDATE_SUCCESS: 'BUNDLE/BUNDLE_UPDATE_SUCCESS',
  BUNDLE_UPDATE_ERROR: 'BUNDLE/BUNDLE_UPDATE_ERROR',
};

export interface BundleState {
  offers?: BundleOffer[];
  isOfferLoading: boolean;
  isOffersLoaded: boolean;
  offerDetails?: BundleOffer;
  isOfferDetailsLoading: boolean;
  isOfferDetailsLoaded: boolean;
  bundleProducts?: BundleProduct[];
  isProductLoading: boolean;
  isProductLoaded: boolean;
}

const initialState: BundleState = {
  isOfferLoading: false,
  isOffersLoaded: false,
  isProductLoading: false,
  isProductLoaded: false,
  isOfferDetailsLoading: false,
  isOfferDetailsLoaded: false,
};

export default (
  state: BundleState = initialState,
  action: any
): BundleState => {
  switch (action.type) {
    case types.RESET_DATA:
      return {
        ...initialState,
      };
    case types.BUNDLE_REQUEST:
      return {
        ...state,
        isOfferLoading: true,
        isOffersLoaded: false,
      };
    case types.BUNDLE_SUCCESS:
      return {
        ...state,
        offers: action.data ? action.data.result : [],
        isOfferLoading: false,
        isOffersLoaded: true,
      };
    case types.BUNDLE_ERROR:
      return {
        ...state,
        isOfferLoading: false,
        isOffersLoaded: true,
      };
    case types.BUNDLE_DETAILS_REQUEST:
      return {
        ...state,
        isOfferDetailsLoading: true,
        isOfferDetailsLoaded: false,
      };
    case types.BUNDLE_DETAILS_SUCCESS:
      return {
        ...state,
        offerDetails: action.data ? action.data.result : [],
        isOfferDetailsLoading: false,
        isOfferDetailsLoaded: true,
      };
    case types.BUNDLE_DETAILS_ERROR:
      return {
        ...state,
        isOfferDetailsLoading: false,
        isOfferDetailsLoaded: true,
      };
    case types.BUNDLE_PRODUCT_REQUEST:
      return {
        ...state,
        isProductLoading: true,
        isProductLoaded: false,
      };
    case types.BUNDLE_PRODUCT_SUCCESS:
      return {
        ...state,
        bundleProducts: action.data ? action.data.result : [],
        isProductLoading: false,
        isProductLoaded: true,
      };
    case types.BUNDLE_PRODUCT_ERROR:
      return {
        ...state,
        isProductLoading: false,
        isProductLoaded: true,
      };
    case types.BUNDLE_ADD_REQUEST:
      return {
        ...state,
      };
    case types.BUNDLE_ADD_SUCCESS:
      return {
        ...state,
      };
    case types.BUNDLE_ADD_ERROR:
      return {
        ...state,
      };
    case types.BUNDLE_DELETE_REQUEST:
      return {
        ...state,
      };
    case types.BUNDLE_DELETE_SUCCESS:
      return {
        ...state,
      };
    case types.BUNDLE_DELETE_ERROR:
      return {
        ...state,
      };
    case types.BUNDLE_UPDATE_REQUEST:
      return {
        ...state,
      };
    case types.BUNDLE_UPDATE_SUCCESS:
      return {
        ...state,
      };
    case types.BUNDLE_UPDATE_ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export const actions = {
  clearData: (onComplete?: () => void) => async (dispatch: Dispatch) => {
    await dispatch({ type: types.RESET_DATA });
    if (onComplete) {
      onComplete();
    }
  },
  getBundle: () => async (dispatch: Dispatch, getState: () => any) => {
    const state = getState();

    if (!state.authUser.authToken) {
      return;
    }
    api.setToken(state.authUser.authToken);
    api.setCustomerLanguage(state.authUser.languageID);
    api.setCustomerLanguageKey(state.authUser.languageKey);
    dispatch({ type: types.BUNDLE_REQUEST });
    try {
      const response = await api.bundleOffer.getBundle();
      const { data } = response;
      dispatch({ type: types.BUNDLE_SUCCESS, data });
    } catch (error) {
      dispatch({ type: types.BUNDLE_ERROR });
      throw error;
    }
  },

  getBundleDetails: (ruleID: number) => async (
    dispatch: Dispatch,
    getState: () => any
  ) => {
    const state = getState();

    if (!state.authUser.authToken) {
      return;
    }
    api.setToken(state.authUser.authToken);
    api.setCustomerLanguage(state.authUser.languageID);
    api.setCustomerLanguageKey(state.authUser.languageKey);
    dispatch({ type: types.BUNDLE_DETAILS_REQUEST });
    try {
      const response = await api.bundleOffer.getBundleProduct(ruleID);
      const { data } = response;
      dispatch({ type: types.BUNDLE_DETAILS_SUCCESS, data });
    } catch (error) {
      dispatch({ type: types.BUNDLE_DETAILS_ERROR });
      throw error;
    }
  },
  getBundleProduct: (ruleID: number) => async (
    dispatch: Dispatch,
    getState: () => any
  ) => {
    const state = getState();

    if (!state.authUser.authToken) {
      return;
    }
    api.setToken(state.authUser.authToken);
    api.setCustomerLanguage(state.authUser.languageID);
    api.setCustomerLanguageKey(state.authUser.languageKey);
    dispatch({ type: types.BUNDLE_PRODUCT_REQUEST });
    try {
      const response = await api.bundleOffer.getBundleProduct(ruleID);
      const { data } = response;
      dispatch({ type: types.BUNDLE_PRODUCT_SUCCESS, data });
    } catch (error) {
      dispatch({ type: types.BUNDLE_PRODUCT_ERROR });
      throw error;
    }
  },

  addBundleProduct: (
    ruleID: number,
    cartID: number,
    onSuccess?: () => void,
    onError?: () => void
  ) => async (dispatch: Dispatch) => {
    dispatch({ type: types.BUNDLE_ADD_REQUEST });
    try {
      const response = await api.bundleOffer.addBundleProduct(ruleID, cartID);
      const { data } = response;
      if (data.response === 'Failure') {
        toastr.error('Error', data.errorMsg);
        dispatch({
          type: types.BUNDLE_ADD_ERROR,
          data: { errorMessage: 'Unable to add cart' },
        });
        if (onError) {
          onError();
        }
      } else {
        dispatch({
          type: types.BUNDLE_ADD_SUCCESS,
          data,
        });
        if (onSuccess) {
          onSuccess();
        }
        toastr.success('Success', 'Product added to cart');
      }
    } catch (error) {
      dispatch({
        type: types.BUNDLE_ADD_ERROR,
        data: { errorMessage: 'Unable to add cart' },
      });
      if (onError) {
        onError();
      }
    }
  },

  updateBundleProduct: (
    bundleID: number,
    cartID: number,
    mode: string,
    quantity: number,
    onSuccess?: () => void,
    onError?: () => void
  ) => async (dispatch: Dispatch) => {
    dispatch({ type: types.BUNDLE_UPDATE_REQUEST });
    try {
      const response = await api.bundleOffer.updateBundleProduct(
        bundleID,
        cartID,
        mode,
        quantity
      );
      const { data } = response;
      if (data.response === 'Failure') {
        toastr.error('Error', data.errorMsg);
        dispatch({
          type: types.BUNDLE_UPDATE_SUCCESS,
          data: {
            errorMessage:
              mode === 'update_bundle_quantity'
                ? 'Unable to update cart'
                : 'Unable to delete cart',
          },
        });
        if (onError) {
          onError();
        }
      } else {
        dispatch({
          type: types.BUNDLE_UPDATE_ERROR,
          data,
        });
        if (onSuccess) {
          onSuccess();
        }
        toastr.success(
          'Success',
          mode === 'update_bundle_quantity'
            ? 'updated cart item'
            : 'Deleted cart item'
        );
      }
    } catch (error) {
      toastr.error('Error', error.response.data.errorMsg);

      dispatch({
        type: types.BUNDLE_ADD_ERROR,
        data: {
          errorMessage:
            mode === 'update_bundle_quantity'
              ? 'Unable to update cart'
              : 'Unable to delete cart',
        },
      });
      if (onError) {
        onError();
      }
    }
  },
};
