import { Dict } from './../../models/interfaces';
import { Dispatch } from 'redux';

import api from '../../api';
import { toastr } from 'react-redux-toastr';
import { types as commonTypes } from './common';
import { State } from '../interfaces';
import {
  Customer,
  CustomerList,
  CustomerCart,
  CustomerOrder,
  HightValueOder,
  Order,
  Performance,
  CreditDetail,
} from '../../models/customer';
import User from '../../models/user';
import { CrudListRequestModel } from '../../api/models';
import i18n from '../../i18n';

// action types
export const types = {
  RESET_DATA: 'COMMON/RESET_DATA',
  // Get customer list
  CUSTOMER_USER_REQUEST: 'USER/CUSTOMER_USER_REQUEST',
  CUSTOMER_USER_SUCCESS: 'USER/CUSTOMER_USER_SUCCESS',
  CUSTOMER_USER_ERROR: 'USER/CUSTOMER_USER_ERROR',

  // Create customer
  CREATE_CUSTOMER_REQUEST: 'USER/CREATE_CUSTOMER_REQUEST',
  CREATE_CUSTOMER_SUCCESS: 'USER/CREATE_CUSTOMER_SUCCESS',
  CREATE_CUSTOMER_ERROR: 'USER/CREATE_CUSTOMER_ERROR',

  // Get customer cart
  CUSTOMER_CART_REQUEST: 'USER/CUSTOMER_CART_REQUEST',
  CUSTOMER_CART_SUCCESS: 'USER/CUSTOMER_CART_SUCCESS',
  CUSTOMER_CART_ERROR: 'USER/CUSTOMER_CART_ERROR',

  // Get customer order
  CUSTOMER_ORDER_REQUEST: 'USER/CUSTOMER_ORDER_REQUEST',
  CUSTOMER_ORDER_SUCCESS: 'USER/CUSTOMER_ORDER_SUCCESS',
  CUSTOMER_ORDER_ERROR: 'USER/CUSTOMER_ORDER_ERROR',

  // Get order
  ORDER_REQUEST: 'USER/ORDER_REQUEST',
  ORDER_SUCCESS: 'USER/ORDER_SUCCESS',
  ORDER_ERROR: 'USER/ORDER_ERROR',

  // Get high order request
  CUSTOMER_HIGHT_ORDER_REQUEST: 'USER/CUSTOMER_HIGHT_ORDER_REQUEST',
  CUSTOMER_HIGHT_ORDER_SUCCESS: 'USER/CUSTOMER_HIGHT_ORDER_SUCCESS',
  CUSTOMER_HIGHT_ORDER_ERROR: 'USER/CUSTOMER_HIGHT_ORDER_ERROR',

  ORDER_UPDATE_REQUEST: 'CART/ORDER_UPDATE_REQUEST',
  ORDER_UPDATE_SUCCESS: 'CART/ORDER_UPDATE_SUCCESS',
  ORDER_UPDATE_ERROR: 'CART/ORDER_UPDATE_ERROR',

  // User profile
  PROFILE_REQUEST: 'USER/PROFILE_REQUEST',
  PROFILE_SUCCESS: 'USER/PROFILE_SUCCESS',
  PROFILE_ERROR: 'USER/PROFILE_ERROR',

  // Chart
  PERFORMANCE_CHART_REQUEST: 'USER/PERFORMANCE_CHART_REQUEST',
  PERFORMANCE_CHART_SUCCESS: 'USER/PERFORMANCE_CHART_SUCCESS',
  PERFORMANCE_CHART_ERROR: 'USER/PERFORMANCE_CHART_ERROR',
};

export interface SalesPersonState {
  customers?: CustomerList[];
  isCustomerLoaded: boolean;
  isCustomerLoading: boolean;
  totalCustomers?: number;
  perPageCount?: number;
  customerCart?: CustomerCart[];
  isCustomerCartLoaded: boolean;
  isCustomerCartLoading: boolean;
  totalCartCount?: number;
  customerOrders?: CustomerOrder[];
  isCustomerOrderLoaded: boolean;
  isCustomerOrderLoading: boolean;
  totalCustomerOrderCount?: number;
  highOrders?: HightValueOder[];
  isHighOrderLoaded: boolean;
  isHighOrderLoading: boolean;
  totalHighOrderCount?: number;
  profileData?: User;
  isProfileLoaded: boolean;
  isProfileLoading: boolean;
  orders?: Order[];
  isOrderLoaded: boolean;
  isOrderLoading: boolean;
  totalOrderCount?: number;
  performanceChart?: Performance;
  isPerformanceLoaded: boolean;
  isPerformanceLoading: boolean;
  creditDetails?: CreditDetail;
}

const initialState: SalesPersonState = {
  isCustomerLoaded: false,
  isCustomerLoading: false,
  isCustomerCartLoaded: false,
  isCustomerCartLoading: false,
  isCustomerOrderLoaded: false,
  isCustomerOrderLoading: false,
  isHighOrderLoaded: false,
  isHighOrderLoading: false,
  isProfileLoaded: false,
  isProfileLoading: false,
  isOrderLoaded: false,
  isOrderLoading: false,
  isPerformanceLoaded: false,
  isPerformanceLoading: false,
};

export default (
  state: SalesPersonState = initialState,
  action: any
): SalesPersonState => {
  switch (action.type) {
    case types.RESET_DATA:
      return {
        ...initialState,
      };

    // customers
    case types.CUSTOMER_USER_REQUEST:
      return { ...state, isCustomerLoading: true };
    case types.CUSTOMER_USER_SUCCESS:
      const customers =
        Number(action.page) <= 1
          ? [...action.data.result]
          : [
              ...(state.customers ? state.customers : []),
              ...action.data.result,
            ];
      return {
        ...state,
        isCustomerLoading: false,
        customers,
        isCustomerLoaded: !!action.data,
        totalCustomers: action.data ? action.data.totalCount : 1,
        perPageCount: 10,
      };
    case types.CUSTOMER_USER_ERROR:
      return { ...state, isCustomerLoading: false, totalCustomers: 0 };

    // customer cart
    case types.CUSTOMER_CART_REQUEST:
      return { ...state, isCustomerCartLoading: true };
    case types.CUSTOMER_CART_SUCCESS:
      const customerCart =
        Number(action.page) <= 1
          ? [...action.data.result]
          : [
              ...(state.customerCart ? state.customerCart : []),
              ...action.data.result,
            ];

      return {
        ...state,
        isCustomerCartLoading: false,
        customerCart,
        isCustomerCartLoaded: !!action.data,
        totalCartCount: action.data ? action.data.totalCount : 1,
        perPageCount: 10,
      };
    case types.CUSTOMER_CART_ERROR:
      return { ...state, isCustomerCartLoading: false, totalCartCount: 0 };

    // customer order
    case types.CUSTOMER_ORDER_REQUEST:
      return { ...state, isCustomerOrderLoading: true };
    case types.CUSTOMER_ORDER_SUCCESS:
      const customerOrders =
        Number(action.page) <= 1
          ? [...action.data.result]
          : [
              ...(state.customerOrders ? state.customerOrders : []),
              ...action.data.result,
            ];

      return {
        ...state,
        isCustomerOrderLoading: false,
        customerOrders,
        isCustomerOrderLoaded: !!action.data,
        totalCustomerOrderCount: action.data ? action.data.totalCount : 1,
        perPageCount: 10,
      };
    case types.CUSTOMER_ORDER_ERROR:
      return {
        ...state,
        isCustomerOrderLoading: false,
        totalCustomerOrderCount: 0,
      };

    // order
    case types.ORDER_REQUEST:
      return {
        ...state,
        isOrderLoading: true,
        isOrderLoaded: Number(action.page) <= 1 ? false : true,
      };
    case types.ORDER_SUCCESS:
      const orders =
        Number(action.page) <= 1
          ? [...action.data.result]
          : [...(state.orders ? state.orders : []), ...action.data.result];

      return {
        ...state,
        isOrderLoading: false,
        orders,
        isOrderLoaded: !!action.data,
        totalOrderCount: action.data ? action.data.totalCount : 1,
        perPageCount: 10,
        creditDetails: action.data?.creditDetails,
      };
    case types.ORDER_ERROR:
      return { ...state, isOrderLoading: false, totalOrderCount: 0 };

    // customer high order
    case types.CUSTOMER_HIGHT_ORDER_REQUEST:
      return { ...state, isHighOrderLoading: true };
    case types.CUSTOMER_HIGHT_ORDER_SUCCESS:
      const highOrders =
        Number(action.page) <= 1
          ? [...action.data.result]
          : [
              ...(state.highOrders ? state.highOrders : []),
              ...action.data.result,
            ];

      return {
        ...state,
        isHighOrderLoading: false,
        highOrders,
        isHighOrderLoaded: !!action.data,
        totalHighOrderCount: action.data ? action.data.totalCount : 1,
        perPageCount: 10,
      };
    case types.CUSTOMER_HIGHT_ORDER_ERROR:
      return { ...state, isHighOrderLoading: false, totalHighOrderCount: 0 };
    // profile
    case types.PROFILE_REQUEST:
      return { ...state, isProfileLoading: true };
    case types.PROFILE_SUCCESS:
      return {
        ...state,
        isProfileLoading: false,
        profileData: action.data ? action.data.result : null,
        isProfileLoaded: !!action.data,
      };
    case types.PROFILE_ERROR:
      return { ...state, isProfileLoading: false };

    // profile
    case types.PERFORMANCE_CHART_REQUEST:
      return { ...state, isPerformanceLoading: true };
    case types.PERFORMANCE_CHART_SUCCESS:
      return {
        ...state,
        isPerformanceLoading: false,
        performanceChart: action.data ? action.data.result : null,
        isPerformanceLoaded: !!action.data,
      };
    case types.PERFORMANCE_CHART_ERROR:
      return { ...state, isPerformanceLoading: false };
    default:
      return state;
  }
};

// action creators & async actions
export const actions = {
  clearData: (onComplete?: () => void) => async (dispatch: Dispatch) => {
    await dispatch({ type: types.RESET_DATA });
    if (onComplete) {
      onComplete();
    }
  },
  customerList: (page: number, keyWord?: string, status?: string) => async (
    dispatch: Dispatch,
    getState: () => any
  ) => {
    const state = getState();

    if (!state.authUser.authToken) {
      return;
    }
    api.setToken(state.authUser.authToken);
    api.setCustomerLanguage(state.authUser.languageID);
    api.setCustomerLanguageKey(state.authUser.languageKey);
    dispatch({ type: types.CUSTOMER_USER_REQUEST });
    try {
      const response = await api.salesperson.getCustomerList(
        page,
        keyWord,
        status
      );
      const { data } = response;
      dispatch({ type: types.CUSTOMER_USER_SUCCESS, data, page });
    } catch (error) {
      dispatch({ type: types.CUSTOMER_USER_ERROR });
      throw error;
    }
  },

  customerCreate: (userData: Customer, onSuccess?: () => void) => async (
    dispatch: Dispatch,
    getState: () => any
  ) => {
    dispatch({ type: types.CREATE_CUSTOMER_REQUEST });
    const state = getState();
    const lng = `${state.authUser.languageKey}`;
    try {
      const response = await api.salesperson.customerCreate(userData);
      const { data } = response;
      if (data.response === 'Failure') {
        dispatch({
          type: types.CREATE_CUSTOMER_SUCCESS,
          data: data.errorMsg,
        });
        toastr.error('Error', data.errorMsg);
      } else {
        dispatch({
          type: types.CREATE_CUSTOMER_ERROR,
          data: response.data.user,
        });
        toastr.success(
          'Success',
          userData.userID
            ? i18n.t('Customer updated successfully', { lng })
            : i18n.t('Customer created successfully', { lng })
        );
        if (onSuccess) onSuccess();
      }
    } catch (error) {
      dispatch({
        type: types.CREATE_CUSTOMER_ERROR,
        data: error.response.data.errors,
      });
      toastr.error('Error', i18n.t('Error updating user', { lng }));
      throw error;
    }
  },

  customerCartList: (page: number, keyWord?: string) => async (
    dispatch: Dispatch,
    getState: () => any
  ) => {
    const state = getState();

    if (!state.authUser.authToken) {
      return;
    }
    api.setToken(state.authUser.authToken);
    api.setCustomerLanguage(state.authUser.languageID);
    api.setCustomerLanguageKey(state.authUser.languageKey);
    dispatch({ type: types.CUSTOMER_CART_REQUEST });
    try {
      const response = await api.salesperson.customerCartList(page, keyWord);
      const { data } = response;
      dispatch({ type: types.CUSTOMER_CART_SUCCESS, data, page });
    } catch (error) {
      dispatch({ type: types.CUSTOMER_USER_ERROR });
      throw error;
    }
  },

  CustomerOrderList: (page: number, filter?: any) => async (
    dispatch: Dispatch,
    getState: () => any
  ) => {
    const state = getState();

    if (!state.authUser.authToken) {
      return;
    }
    api.setToken(state.authUser.authToken);
    api.setCustomerLanguage(state.authUser.languageID);
    api.setCustomerLanguageKey(state.authUser.languageKey);
    dispatch({ type: types.CUSTOMER_ORDER_REQUEST });
    try {
      const response = await api.salesperson.CustomerOrderList(page, filter);
      const { data } = response;
      dispatch({ type: types.CUSTOMER_ORDER_SUCCESS, data, page });
    } catch (error) {
      dispatch({ type: types.CUSTOMER_ORDER_ERROR });
      throw error;
    }
  },

  OrderList: (page: number, filter?: any) => async (
    dispatch: Dispatch,
    getState: () => any
  ) => {
    const state = getState();

    if (!state.authUser.authToken) {
      return;
    }
    api.setToken(state.authUser.authToken);
    api.setCustomerLanguage(state.authUser.languageID);
    api.setCustomerLanguageKey(state.authUser.languageKey);
    dispatch({ type: types.ORDER_REQUEST, page });
    try {
      const response = await api.salesperson.OrderList(page, filter);
      const { data } = response;
      dispatch({ type: types.ORDER_SUCCESS, data, page });
    } catch (error) {
      dispatch({ type: types.ORDER_ERROR });
      throw error;
    }
  },

  CustomerHighOrderList: (page: number, keyWord?: string) => async (
    dispatch: Dispatch,
    getState: () => any
  ) => {
    const state = getState();

    if (!state.authUser.authToken) {
      return;
    }
    api.setToken(state.authUser.authToken);
    api.setCustomerLanguage(state.authUser.languageID);
    api.setCustomerLanguageKey(state.authUser.languageKey);
    dispatch({ type: types.CUSTOMER_HIGHT_ORDER_REQUEST });
    try {
      const response = await api.salesperson.CustomerHighOrderList(
        page,
        keyWord
      );
      const { data } = response;
      dispatch({ type: types.CUSTOMER_HIGHT_ORDER_SUCCESS, data, page });
    } catch (error) {
      dispatch({ type: types.CUSTOMER_HIGHT_ORDER_ERROR });
      throw error;
    }
  },

  approveOrder: (
    orderID: number,
    status: string,
    onSuccess?: () => void
  ) => async (dispatch: Dispatch, getState: () => any) => {
    dispatch({ type: types.ORDER_UPDATE_REQUEST });
    const state = getState();
    const lng = `${state.authUser.languageKey}`;
    try {
      const response = await api.salesperson.approveOrder(orderID, status);
      const { data } = response;
      if (data.response === 'Failure') {
        toastr.error('Error', data.errorMsg);
      } else {
        dispatch({
          type: types.ORDER_UPDATE_SUCCESS,
          data,
        });
        if (onSuccess) onSuccess();
      }
    } catch (error) {
      dispatch({
        type: types.ORDER_UPDATE_ERROR,
        data: { errorMessage: i18n.t('Unable to update status', { lng }) },
      });
    }
  },
  fetchProfile: (onSuccess?: () => void) => async (
    dispatch: Dispatch,
    getState: () => any
  ) => {
    const state = getState();
    if (!state.authUser.authToken) {
      return;
    }
    api.setToken(state.authUser.authToken);
    api.setCustomerLanguage(state.authUser.languageID);
    api.setCustomerLanguageKey(state.authUser.languageKey);
    dispatch({ type: types.PROFILE_REQUEST });
    try {
      const response = await api.salesperson.getProfile();
      const { data } = response;
      dispatch({ type: types.PROFILE_SUCCESS, data });
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      dispatch({ type: types.PROFILE_ERROR });
      // toastr.error('Error', 'Error Fetching profile');
      throw error;
    }
  },
  getPerformanceChart: (
    type?: string,
    fromDate?: string,
    toDate?: string,
    onSuccess?: () => void
  ) => async (dispatch: Dispatch, getState: () => any) => {
    const state = getState();
    if (!state.authUser.authToken) {
      return;
    }
    api.setToken(state.authUser.authToken);
    api.setCustomerLanguage(state.authUser.languageID);
    api.setCustomerLanguageKey(state.authUser.languageKey);
    dispatch({ type: types.PERFORMANCE_CHART_REQUEST });
    try {
      const response = await api.salesperson.getPerformanceChart(
        type,
        fromDate,
        toDate
      );
      const { data } = response;
      dispatch({ type: types.PERFORMANCE_CHART_SUCCESS, data });
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      dispatch({ type: types.PERFORMANCE_CHART_ERROR });
      // toastr.error('Error', 'Error Fetching profile');
      throw error;
    }
  },
  changeOrderStatus: (
    orderID: number,
    status: string,
    reason?: string,
    onSuccess?: () => void,
    onError?: () => void
  ) => async (dispatch: Dispatch, getState: () => any) => {
    dispatch({ type: types.ORDER_UPDATE_REQUEST });
    const state = getState();
    const lng = `${state.authUser.languageKey}`;
    try {
      const response = await api.salesperson.changeOrderStatus(
        orderID,
        status,
        reason
      );
      const { data } = response;
      if (data.response === 'Failure') {
        toastr.error('Error', data.errorMsg);
        if (onError) onError();
      } else {
        dispatch({
          type: types.ORDER_UPDATE_SUCCESS,
          data,
        });
        if (onSuccess) onSuccess();
      }
    } catch (error) {
      dispatch({
        type: types.ORDER_UPDATE_ERROR,
        data: {
          errorMessage: i18n.t('Unable to change order status', { lng }),
        },
      });
      if (onError) onError();
    }
  },
};
